export const links = {
	// bearerUrl: 'https://credite.test/api/v1',
	// galleryUrl: 'https://credite.test/storage',
	bearerUrl: 'https://backend.kcmfb.com/api/v1',
	galleryUrl: 'https://backend.kcmfb.com/storage',
	pages: {
		login: 'auth/login',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'admin/profile',
		editProfile: 'admin/edit-user',
		logout: 'logout',
		loans: 'admin/loans',
		processLoan: 'admin/loans/processLoan',
		offers: 'admin/offers',
		repayments: 'admin/repayments',
		liquidate: 'admin/loans/liquidate/loan',
		markAspaid: 'admin/loans/markAsPaid/loan',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		agents: 'admin/agents',
		customers: 'admin/customers',
		approval: 'admin/agents/approval',
		perm: 'admin/users/perm',
		notifications: 'admin/notifications',
		sms: 'admin/sms',
		markMethodAsAttached: 'admin/offers/markAttached/pay_methods',
		deleteMethod: 'admin/offers/delete/pay_methods',
		packages: 'admin/packages',
		pools: 'admin/pools',
		investments: 'admin/investments',
		addInvestment: 'admin/investments/create-administrative-investment',
		activateInvestment: 'admin/investments/process/activate',
		releaseInvestment: 'admin/release_investment',
		createLetter: 'admin/investments/create-letter',
		deleteInvestment: 'admin/investments/delete',
		sendInvestmentFunds: 'admin/send_investment_funds',
		savings: 'admin/savings',
		releaseSaving: 'admin/release_saving',
		sendSavingFunds: 'admin/send_saving_funds',
		downloadStatement: 'admin/loans/statements/account',
		roles: 'admin/roles',
		permissions: 'admin/permissions',
		invite_links: 'admin/invite_links',
		administrators: 'admin/administrators',
		admin_invite: 'admin/create',
		verify_invite_link: 'admin/invite/verify',
		join_team: 'admin/administrators/store',
		profile_update: 'profile/update',
		loanReport: 'admin/reports/loan_report',
		customerReport: 'admin/reports/customer_report',
		savingReport: 'admin/reports/saving_report',
		investmentReport: 'admin/reports/investment_report',
		investmentCert: 'admin/investments/preview',
		investmentLetter: 'admin/investments/preview/letter',
		withdrawAndRollInvestment: 'admin/investments/withdraw-and-rollover',
		addTopUpToInvestment: 'admin/investments/add-top-up',
		dashboard: 'admin/dashboard',
		importLoan: 'admin/imports/loan-imports',
		importRepayment: 'admin/imports/repayment-imports',
		importHistory: 'admin/imports/history',
		storeStaffID: 'admin/imports/create_staff_id',
		reverseImport: 'admin/imports/reverse_import',
		rolloverInvestment: 'admin/investments/rollover/matured',
	},
	apis: {
		paystack: {
			public_key: 'FLWPUBK-f942e0902cdebc16dec3db93b182e6bd-X',
			secret_key: 'FLWSECK-b000a34fce4ff3813a5440d544f343c0-X',
		},
		mono: {
			public_key: 'live_pk_N2Lx6IRNfE6BJWr1TLym',
			secret_key: 'live_sk_8M1G3dWOYuHNW0gsD7lv',
		},
	},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
